import React from 'react';

function Partners() {
    return (
        <section id="partners" className="py-5">
            <div className="container text-center">
                <h2>Parceiros</h2>
                <p>Lista de parceiros...</p>
            </div>
        </section>
    );
}

export default Partners;

import React from 'react';

function Contacts() {
    return (
        <section id="contacts" className="py-5 bg-light">
            <div className="container text-center">
                <h2>Contatos</h2>
                <p>Tiago Gonçalves 18 99815-1884</p>
                <p>contato@caravanafielassis.com.br</p>
            </div>
        </section>
    );
}

export default Contacts;
